import { forwardRef, Ref, useState, ReactElement, ChangeEvent } from "react";
import {
  Avatar,
  Link,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  lighten,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
  Theme,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Hidden,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AppBlockingIcon from "@mui/icons-material/AppBlocking";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CSTE_ROUTES } from "src/commons/constants";
import AISRouterClass from "src/commons/ais-classes/router/ais-router";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useLocation } from "react-router-dom";

const Transition = forwardRef(
  (props: TransitionProps & {children: ReactElement<any, any>}, ref: Ref<unknown>) => (
    <Slide direction="down" ref={ref} {...props} />
  ),
);

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`,
);

const SearchInputWrapper = styled(TextField)(
  ({theme}) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`,
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({theme}) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`,
);

const quickOptions = {
  administrator: [
    {
      title: 'Create a User',
      subtitle: ' This link allows you to directly access the form for creating a new user',
      route: CSTE_ROUTES.ADMINISTRATOR.USERS.EDIT,
      avatar: <PersonAddIcon />,
    },
    {
      title: 'Create a new user profile',
      subtitle: ' This link gives you direct access to the form for creating a new profile.',
      route: CSTE_ROUTES.ADMINISTRATOR.PROFILES.EDIT,
      avatar: <AddModeratorIcon />,
    },
    {
      title: 'Devices - Inactivate / Activate an Ipad',
      subtitle: ' This link allows you to directly access the option to block an Ipad.',
      route: CSTE_ROUTES.ADMINISTRATOR.DEVICES.LIST,
      avatar: <AppBlockingIcon />,
    },
    {
      title: 'Upload a new CMM file or a revision CMM file',
      subtitle: ' This option allows you to upload a  CMM PDF files to AirInt services.',
      route: CSTE_ROUTES.ADMINISTRATOR.UPLOADSCMM.UPLOADSUBMIT,
      avatar: <CloudUploadIcon />,
    },
  ],
  webreporting: [],
};

function HeaderSearch() {
  const [openSearchResults, setOpenSearchResults] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [router] = useState(new AISRouterClass());

  const sampleLocation = useLocation();
  const quickOptionsItem: [any] = quickOptions[sampleLocation.pathname.split('/')[1]];

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(true);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShortcut = (route: string) => {
    setOpen(false);
    router.navigate(route);
  };

  return (
    <>
      <Tooltip arrow title="Quick options">
        <IconButton color="primary" onClick={handleClickOpen}>
          <PostAddIcon />
        </IconButton>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <DialogTitleWrapper>
          <SearchInputWrapper
            value={searchValue}
            autoFocus
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            disabled
            placeholder="this option is under construction ..."
            fullWidth
            label="Search"
          />
        </DialogTitleWrapper>
        <Divider />

        {openSearchResults && (
          <DialogContent>
            <Box sx={{pt: 0, pb: 1}} display="flex" justifyContent="space-between">
              <Typography variant="body2" component="span">
                Quick access to the main options
                <Typography sx={{fontWeight: 'bold'}} variant="body1" component="span" />
              </Typography>
            </Box>
            <Divider sx={{my: 1}} />
            <List disablePadding>
              {quickOptionsItem &&
                quickOptionsItem.map((option: any, index: number) => (
                  <Box key={`box-${index}`}>
                    <ListItem
                      key={`quickOption-${index}`}
                      button
                      onClick={() => {
                        handleClickShortcut(option.route);
                      }}
                    >
                      <Hidden smDown>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              background: (theme: Theme) => theme.palette.secondary.main,
                            }}
                          >
                            {option.avatar}
                          </Avatar>
                        </ListItemAvatar>
                      </Hidden>
                      <Box flex="1">
                        <Box display="flex" justifyContent="space-between">
                          <Link
                            href={option.route}
                            underline="hover"
                            sx={{fontWeight: 'bold'}}
                            variant="body2"
                          >
                            {option.title}
                          </Link>
                        </Box>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{
                            color: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
                          }}
                        >
                          {option.subtitle}
                        </Typography>
                      </Box>
                    </ListItem>

                    <Divider key={`quickOptionDivider-${index}`} sx={{my: 1}} component="li" />
                  </Box>
                ))}
            </List>

            <Box sx={{textAlign: 'center'}}>
              <Button
                color="primary"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        )}
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
