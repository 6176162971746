import {LinearProgress, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {CSTE_SSO_LOGIN} from 'src/commons/constants';
import {AISUserService} from 'src/commons/services/dataServices';
import {useTheme} from '@mui/material/styles';
import {AISCacheService} from 'src/commons/services/cacheService';
import {useState} from 'react';
import DividerWrapper from '../../Typography/Divider-wrapper';

function SSOLogin() {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const customer = AISCacheService.getSSOCustom();

  const clickSSOLogin = async () => {
    setLoading(true);

    await AISUserService.setFrontUrlInSession(window.location.origin);
    let redirectUri = '';
    if (customer === 'habile') {
      redirectUri = AISUserService.getAuthUrlSSO(customer);
    } else {
      redirectUri = AISUserService.getAuthUrlSSO(customer);
    }

    window.location.href = redirectUri;
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  return customer && Object.prototype.hasOwnProperty.call(CSTE_SSO_LOGIN, customer) ? (
    <>
      <Stack
        direction={'column'}
        sx={{':hover': {cursor: 'pointer'}}}
        border={`1px solid ${theme.colors.primary.main}`}
        p={3}
        borderRadius={1}
        onClick={clickSSOLogin}
        justifyItems={'center'}
        justifyContent={'center'}
      >
        <img src={CSTE_SSO_LOGIN[customer].image} height={30} />

        <Typography variant="body1" color={theme.colors.primary.light} fontWeight="normal">
          {loading ? 'Connection in progress...' : CSTE_SSO_LOGIN[customer].label}
        </Typography>

        {loading && <LinearProgress />}
      </Stack>
      <DividerWrapper text={'or'} paddingBottom={5} paddingTop={5} />
    </>
  ) : (
    <></>
  );
}

export default SSOLogin;
