import { useContext } from 'react';

import {
  ListSubheader,
  List,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/commons/contexts/SidebarContext';


import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import HomeIcon from '@mui/icons-material/Home';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { CSTE_ROUTES } from 'src/commons/constants';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import ArticleIcon from '@mui/icons-material/Article';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {MenuWrapper} from 'src/commons/components/SidebarMenuWrapper/menuWrapper';
import {SubMenuWrapper} from 'src/commons/components/SidebarMenuWrapper/subMenuWrapper';

function SidebarMenuAdmin() {
  const {closeSidebar} = useContext(SidebarContext);

  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.ADMINISTRATOR.OVERVIEW}
                startIcon={<HomeIcon />}
              >
                Overview
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Accounts & permissions
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.ADMINISTRATOR.USERS.LIST}
                startIcon={<AccountCircleTwoToneIcon />}
              >
                Users
              </Button>
            </ListItem>
            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.ADMINISTRATOR.PROFILES.MAINPAGE}
                startIcon={<AdminPanelSettingsIcon />}
              >
                Profiles
              </Button>
            </ListItem> */}
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Tools
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.ADMINISTRATOR.DEVICES.LIST}
                startIcon={<TabletMacIcon />}
              >
                Devices
              </Button>
            </ListItem>

            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.ADMINISTRATOR.DOCUMENTS.LIST}
                startIcon={<ArticleIcon />}
              >
                Documents
              </Button>
            </ListItem>

            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.ADMINISTRATOR.UPLOADSCMM.UPLOADLIST}
                startIcon={<CloudDownloadIcon />}
              >
                Document Submission
              </Button>
            </ListItem>

            {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={CSTE_ROUTES.ADMINISTRATOR.DOCUMENTS.LIST}
                  startIcon={<LinkIcon />}
                >
                  Linked data
                </Button>
              </ListItem> */}
          </List>
        </SubMenuWrapper>
        `
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenuAdmin;
