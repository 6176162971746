import { useContext } from 'react';

import {
  ListSubheader,
  List,
  Button,
  ListItem,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/commons/contexts/SidebarContext';
import HomeIcon from '@mui/icons-material/Home';
import { CSTE_ROUTES } from 'src/commons/constants';
import TuneIcon from '@mui/icons-material/Tune';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ListAltIcon from '@mui/icons-material/ListAlt';
import KeyIcon from '@mui/icons-material/Key';
import TerminalIcon from '@mui/icons-material/Terminal';
import PaletteIcon from '@mui/icons-material/Palette';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { MenuWrapper } from 'src/commons/components/SidebarMenuWrapper/menuWrapper';
import { SubMenuWrapper } from 'src/commons/components/SidebarMenuWrapper/subMenuWrapper';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

function SidebarMenuWebReporting() {
  const {closeSidebar} = useContext(SidebarContext);

  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.OVERVIEW}
                startIcon={<HomeIcon />}
              >
                Overview
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Dispatcher Environment
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.DISPATCHER.ENVS.INDEX}
                startIcon={<KeyIcon />}
              >
                Corporate Keys
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.DISPATCHER.ENVVARS.INDEX}
                startIcon={<TerminalIcon />}
              >
                Environment variables
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Configuration
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.THEME.SETTINGS}
                startIcon={<PaletteIcon />}
              >
                Theme
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.SSO.SETTINGS}
                startIcon={<VpnKeyIcon />}
              >
                Login & SSO
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.GENERALSETTINGS.INDEX}
                startIcon={<TuneIcon />}
              >
                General settings
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.PROFILES.MAINPAGE}
                startIcon={<AdminPanelSettingsIcon />}
              >
                Profiles
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.INTEROPS.INDEX}
                startIcon={<ListAltIcon />}
              >
                Customer Interops
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.CONFIGURATOR.TABLES.LIST}
                startIcon={<AppRegistrationIcon />}
              >
                Base tables
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenuWebReporting;
