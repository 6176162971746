import {
  alpha,
  Badge,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import {useEffect, useRef, useState} from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import {styled} from '@mui/material/styles';
import {formatDistance} from 'date-fns';
import {CSTE_ROUTES} from 'src/commons/constants';
import LinkButton from 'src/commons/components/Buttons/LinkButton';
import {AISNotificationService} from 'src/commons/services/dataServices';
import AISRouterClass from 'src/commons/ais-classes/router/ais-router';

const NotificationsBadge = styled(Badge)(
  ({theme}) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [nbNotifications] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<any[]>([]);
  const router = new AISRouterClass();

  useEffect(() => {
    getListNotification();
  }, []);

  const getListNotification = async () => {
    try {
      const {data: res} = await AISNotificationService.getListNotification();

      if (res.status === 200) {
        setNotifications(res.results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleOpen = (): void => {
    getListNotification();
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const notificationComponent = (notification, index) => {
    if (index >= 3) {
      return <></>;
    }
    return (
      <div key={`notif-${index}`}>
        <ListItem
          sx={{
            p: 1,
            minWidth: 350,
            display: {xs: 'block', sm: 'flex'},
          }}
        >
          <Box flex="1">
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{fontWeight: 'bold'}}>{notification.title}</Typography>
              <Typography variant="caption" sx={{textTransform: 'none'}}>
                {formatDistance(new Date(notification.date), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            </Box>
            <Typography component="span" variant="body2" color="text.secondary">
              {' '}
              {notification.subtitle}
            </Typography>
          </Box>
        </ListItem>
        <Divider />
      </div>
    );
  };

  return (
    <>
      <Tooltip arrow title="Messages">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={nbNotifications}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MessageIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{p: 2}} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Messages</Typography>
          <IconButton
            onClick={() => {
              router.navigate(CSTE_ROUTES.COMMONS.NOTIFICATION.CREATE);
              setOpen(false);
            }}
            sx={{
              p: 1,
            }}
            size="small"
            color="primary"
          >
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <List sx={{p: 0}}>
          {loading && (
            <Box sx={{display: 'flex'}} p={2} alignItems={'center'}>
              <CircularProgress size={'16px'} />
              <Typography ml={2} sx={{fontWeight: 'bold'}}>
                Loading in progress...
              </Typography>
            </Box>
          )}
          {notifications.length > 0 &&
            notifications.map((notification, index) => notificationComponent(notification, index))}
          <Box display="flex" justifyContent="end" p={1}>
            <LinkButton
              onClick={() => {
                setOpen(false);
              }}
              label="Show more"
              href={CSTE_ROUTES.COMMONS.NOTIFICATION.LIST}
            />
          </Box>
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
