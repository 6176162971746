import {Box, Chip, IconButton, Link, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import {useAuth} from 'src/commons/contexts/AuthContext';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {useContext} from 'react';
import {SidebarContext} from 'src/commons/contexts/SidebarContext';
import {Stack} from '@mui/system';
import {AISCacheService} from 'src/commons/services/cacheService';
import LogoAirInt from 'src/commons/components/Logo/LogoAirIntServices';

const ListWrapper = styled(Box)(
  ({theme}) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`,
);

function HeaderMenu() {
  const {user} = useAuth();
  const {sidebarHidden, hideSidebar, ShowSidebar} = useContext(SidebarContext);

  const toogleSideBarHidden = () => {
    if (sidebarHidden) {
      ShowSidebar();
    } else {
      hideSidebar();
    }
  };

  const hrefModule = AISCacheService.getBaseModuleHref();

  const env : string = AISCacheService.getEnvironment()
  
  const theme = useTheme()

  return (
    <ListWrapper
      alignItems={'center'}
      sx={{
        alignItems: 'center',
      }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <IconButton
          onClick={toogleSideBarHidden}
          sx={{
            marginRight: 1,
            display: {
              xs: 'none',
              lg: 'inline-block',
            },
            transform: sidebarHidden ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          color="primary"
        >
          <MenuOpenIcon
            fontSize="large"
            sx={{mt: sidebarHidden ? 0 : 0.8, mb: sidebarHidden ? -0.9 : 0}}
          />
        </IconButton>
        <Link
          href={hrefModule}
          sx={{
            display: {
              xs: 'inline-block',
              lg: 'none',
            },
            mr: 3,
          }}
        >
          <LogoAirInt width={180} height={50} />
        </Link>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          p={0.5}
          px={1}
          borderRadius={5}
          sx={{backgroundColor: theme.colors.primary.lighter}}
        >
          <AirplanemodeActiveIcon sx={{ml: 2, color: theme.colors.primary.main}} />
          <Typography variant="h4" fontWeight={'bold'} color={theme.colors.alpha.black[70]}>
            {user.customer}
          </Typography>
          <Chip
            color={['STAGING', 'RC', 'TRAINING'].includes(env.toUpperCase()) ? 'warning' : 'error'}
            label={env}
            variant="filled"
            sx={{fontWeight: 'bold'}}
          />
        </Stack>

        {/* <Chip  icon={<AirplanemodeActiveIcon />} color="default" label={user.customer} variant="filled" /> */}
      </Stack>
    </ListWrapper>
  );
}

export default HeaderMenu;

/*

*/
