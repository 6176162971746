import { useContext } from "react";

import { ListSubheader, List, Button, ListItem } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/commons/contexts/SidebarContext";
import HomeIcon from "@mui/icons-material/Home";
import { CSTE_ROUTES } from "src/commons/constants";
import BarChartIcon from "@mui/icons-material/BarChart";
import SensorsIcon from "@mui/icons-material/Sensors";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {MenuWrapper} from 'src/commons/components/SidebarMenuWrapper/menuWrapper';
import {SubMenuWrapper} from 'src/commons/components/SidebarMenuWrapper/subMenuWrapper';
import {useAuth} from 'src/commons/contexts/AuthContext';

function SidebarMenuWebReporting() {
  const {closeSidebar} = useContext(SidebarContext);
  const {hasPermission} = useAuth();

  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.WEBREPORTING.OVERVIEW}
                startIcon={<HomeIcon />}
              >
                Dashboard
              </Button>
            </ListItem>
            {hasPermission(CSTE_ROUTES.WEBREPORTING.REPORTS.WEEKLYREPORT) && (
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={CSTE_ROUTES.WEBREPORTING.REPORTS.WEEKLYREPORT}
                  startIcon={<DateRangeIcon />}
                >
                  Weekly Report
                </Button>
              </ListItem>
            )}
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Data Handling
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={CSTE_ROUTES.WEBREPORTING.OVERVIEW}
                  startIcon={<BarChartIcon />}
                >
                  Report 1
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={CSTE_ROUTES.WEBREPORTING.OVERVIEW}
                  startIcon={<PieChartIcon />}
                >
                  Report 2
                </Button>
              </ListItem> */}
            {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={CSTE_ROUTES.WEBREPORTING.DATAEXPLORER.INDEX}
                  startIcon={<ManageSearchIcon />}
                >
                  Data explorer
                </Button>
              </ListItem> */}
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to={CSTE_ROUTES.WEBREPORTING.MAINTENANCE.INDEX}
                startIcon={<BarChartIcon />}
              >
                Maintenance
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      {hasPermission(CSTE_ROUTES.WEBREPORTING.FLEET.AIRCRAFTLIST) && (
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Fleet
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={CSTE_ROUTES.WEBREPORTING.FLEET.AIRCRAFTLIST}
                  startIcon={<AirplanemodeActiveIcon />}
                >
                  Aircraft list
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
          `
        </List>
      )}

      {hasPermission(CSTE_ROUTES.WEBREPORTING.REPORTS.RFIDINVENTORIES) && (
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Reports
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={CSTE_ROUTES.WEBREPORTING.REPORTS.RFIDINVENTORIES}
                  startIcon={<SensorsIcon />}
                >
                  Inventory
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      )}
    </MenuWrapper>
  );
}

export default SidebarMenuWebReporting;
