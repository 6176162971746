import { useRoutes } from 'react-router-dom';
import React from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import router from './commons/ais-classes/router/router';
import ThemeProvider from './theme/ThemeProvider';
import { AuthProvider, ProtectRoute } from './commons/contexts/AuthContext';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <AuthProvider>
          <ProtectRoute>
            {content}
          </ProtectRoute>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;


